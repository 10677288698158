import React, {
	useEffect,
	useState,
	useCallback,
	useContext,
	useRef,
} from "react";
import { GlobalContext, AppContext } from "../../../helpers/global-context";
import DashboardTemplate from "../../../template/dashboard";
import {
	useLazyQuery,
	useMutation,
	useQuery,
	useApolloClient,
} from "@apollo/client";
import { APPROVED, LOCATIONS } from "../../../graphql/queries";
import moment from "moment";
import { UPDATE_APPLICATION_STATUS } from "../../../graphql/muattions";
import { message, Spin, Form } from "antd";
import ApprovedView from "./approved-view";
import { debounce } from "lodash";
import { useReactToPrint } from "react-to-print";
import Activity from "./activity";

let counter = 0;

const ApprovedController = () => {
	let heatMapData = {
		positions: [],
		options: {
			radius: 20,
			opacity: 0.6,
		},
	};

	const client = useApolloClient();

	const { municipality_code } = useContext(AppContext);
	const [showGoogleMap, setShowGoogleMap] = useState(false);
	const [offset, setOffset] = useState(0);
	const [dataSource, setDataSource] = useState([]);
	const [total, setTotal] = useState(0);
	const [showNotes, setShowNotes] = useState(false);
	const [showID, setShowID] = useState(false);
	const [info, setInfo] = useState(null);
	const [print, setPrint] = useState(null);
	const printRef = useRef();
	const [activityLogs, setActivityLogs] = useState([]);
	const [form] = Form.useForm();
	const [search, setSearch] = useState("EMPTY");
	const [ascend, setAscend] = useState(true);
	const [currentPosition, setCurrentPosition] = useState({});
	const [center, setCenter] = useState({
		center: {
			lat: 11.270775,
			lng: 123.347328,
		},
		zoom: 6,
	});
	const [toggleHeapMap, setToggleHeapMap] = useState(heatMapData);
	const [is_admin] = useState(
		localStorage.getItem("is_admin") === "0" ? null : false
	);
	const [where, setWhere] = useState({
		_and: {
			status: { _eq: "Approved" },
			municipality_code: { _eq: municipality_code },
			is_admin: { _eq: is_admin },
		},
	});

	const [forApproval, { data, loading }] = useLazyQuery(APPROVED, {
		fetchPolicy: "no-cache",
	});
	const [application_id, setApplicationId] = useState(null);
	const [notes, setNotes] = useState(null);

	const [updateApplicationStatus] = useMutation(UPDATE_APPLICATION_STATUS, {
		onCompleted(res) {
			message.success(
				`Application ${res.update_workpass_applications.returning[0].status}`
			);
			triggerQuery();
		},
	});

	const triggerQuery = () => {
		forApproval({
			variables: {
				where,
				offset,
				order: ascend ? "asc" : "desc",
			},
		});
	};

	const { data: locations } = useQuery(LOCATIONS, {
		fetchPolicy: "network-only",
		variables: {
			where: {
				app_user_unique_id: {
					_eq: search,
				},
			},
		},
	});

	const _debouncedSearch = useCallback(debounce(triggerQuery, 500), [
		where,
		offset,
		ascend,
	]);

	const _searchHandler = (e) => {
		const val = e.target.value;
		if (val.length) {
			setWhere({
				...where,
				_or: [
					{ first_name: { _ilike: `%${val}%` } },
					{ last_name: { _ilike: `%${val}%` } },
				],
			});
			setOffset(0);
		} else {
			const remove_where = { ...where };
			delete remove_where?._or;
			setWhere(remove_where);
			setOffset(0);
		}
	};

	const _onSearchHandler = (data) => {
		if (data.first_name || data.last_name) {
			setWhere({
				...where,
				first_name: { _ilike: `%${data?.first_name ?? ""}%` },
				last_name: { _ilike: `%${data?.last_name ?? ""}%` },
			});
			setOffset(0);
		} else {
			form.setFieldsValue({ first_name: null, last_name: null });
			const remove_where = { ...where };
			delete remove_where?.first_name;
			delete remove_where?.last_name;
			setWhere(remove_where);
			setOffset(0);
		}
	};

	useEffect(() => {
		_debouncedSearch();
	}, [offset, where, ascend]);

	useEffect(() => {
		if (data) {
			const items = data.workpass_applications.map((application) => {
				const {
					first_name,
					last_name,
					company_name,
					destination_barangay,
					destination_location,
					destination_municipality,
					destination_province,
					municipality_code,
					id,
					office_number,
					origin_barangay,
					origin_location,
					origin_municipality,
					origin_province,
					status,
					created_at,
					workpass_application_files,
					unique_id,
				} = application;
				return {
					key: `${id}`,
					unique_id,
					name: `${first_name} ${last_name}`,
					company: {
						company_name,
						office_number,
					},
					origin: {
						origin_barangay,
						origin_location,
						origin_municipality,
						origin_province,
					},
					destination: {
						destination_barangay,
						destination_location,
						destination_municipality,
						destination_province,
						municipality_code,
					},
					id,
					status,
					municipality_code,
					workpass_application_files,
					request_date: moment(created_at).format("LLL"),
					picture: workpass_application_files.filter(
						({ type }) => type === "picture"
					)?.[0]?.url,
				};
			});
			setDataSource(items);
			setTotal(data.workpass_applications_aggregate.aggregate.count);
		}
	}, [data]);

	useEffect(() => {
		if (
			locations &&
			locations.mobile_app_user_locations &&
			locations.mobile_app_user_locations.length
		) {
			setCenter({
				center: {
					lat: +locations.mobile_app_user_locations[0].lat,
					lng: +locations.mobile_app_user_locations[0].lng,
				},
				zoom: 12,
			});
			setCurrentPosition({
				lat: +locations.mobile_app_user_locations[0].lat,
				lng: +locations.mobile_app_user_locations[0].lng,
				created_at: locations.mobile_app_user_locations[0].created_at,
			});
			setToggleHeapMap({
				positions: locations.mobile_app_user_locations,
				options: {
					radius: 20,
					opacity: 0.6,
				},
			});
			setShowGoogleMap(true);
		} else if (
			locations &&
			locations.mobile_app_user_locations &&
			locations.mobile_app_user_locations.length <= 0 &&
			search !== "EMPTY"
		) {
			alert("Locations are empty. Nothing to display!");
		}
	}, [locations]);

	useEffect(() => {
		if (
			locations &&
			locations.mobile_app_user_locations &&
			locations.mobile_app_user_locations.length
		) {
			let userPlaces = [];
			let lastId = null;

			locations.mobile_app_user_locations.forEach(
				(mobile_app_user_location) => {
					let id = `${mobile_app_user_location.lat}-${mobile_app_user_location.lng}`;
					if (id != lastId) {
						userPlaces.push({
							place: mobile_app_user_location,
							stationary: [],
						});
					}
					userPlaces[userPlaces.length - 1].place = mobile_app_user_location;
					userPlaces[userPlaces.length - 1].stationary.push(
						mobile_app_user_location
					);
					lastId = id;
				}
			);

			userPlaces.map((userPlace, index) => {
				activityLogs.push(
					<Activity userPlace={userPlace} recenter={recenter} />
				);
			});
			setActivityLogs(activityLogs);
		}
	}, [locations]);

	let recenter = (mobile_app_user_location) => {
		return () => {
			setCurrentPosition(mobile_app_user_location);
			counter = mobile_app_user_location.length;
		};
	};

	const _offsetHandler = (page) => {
		setOffset((page - 1) * 5);
	};

	const _approveHandler = ({ id }) => {
		updateApplicationStatus({
			variables: {
				id,
				status: "Approved",
				is_admin: false,
			},
		});
	};

	const _denyHandler = () => {
		setShowNotes(false);
		updateApplicationStatus({
			variables: {
				id: application_id,
				status: "Denied",
				notes,
				is_admin: false,
			},
		});
	};

	const _viewHandler = (data) => {
		console.log(data);
	};

	const _viewIDHandler = (val) => {
		setShowID(true);
		const data = {
			...val,
			workpass_application_files: val.workpass_application_files.filter(
				({ type }) => type === "picture"
			),
		};
		setInfo({ ...data });
	};

	const _printIDHandler = (val) => {
		setShowID(true);
		const data = {
			...val,
			workpass_application_files: val.workpass_application_files.filter(
				({ type }) => type === "picture"
			),
		};
		setPrint({ ...data });
	};

	const _printHandler = useReactToPrint({
		content: () => printRef.current,
	});

	const _endCaptureHandler = (data) => {
		console.log(data);
	};

	const _setHandler = ({ id }) => {
		updateApplicationStatus({
			variables: {
				id,
				status: "Approved",
				is_admin: true,
			},
		});
	};

	const onSearch = (value) => {
		setSearch(value.unique_id);
	};

	const values = {
		dataSource,
		offset,
		total,
		showNotes,
		showID,
		info,
		printRef,
		ascend,
		print,
		showGoogleMap,
		center,
		currentPosition,
		toggleHeapMap,
		activityLogs,
		setShowGoogleMap,
		onSearch,
		setAscend,
		_printHandler,
		setInfo,
		setShowID,
		setOffset,
		_offsetHandler,
		_approveHandler,
		_denyHandler,
		_viewHandler,
		setShowNotes,
		_searchHandler,
		setApplicationId,
		setNotes,
		_viewIDHandler,
		_endCaptureHandler,
		_setHandler,
		_onSearchHandler,
		_printIDHandler,
	};

	return (
		<GlobalContext.Provider value={values}>
			<Spin spinning={loading}>
				<ApprovedView />
			</Spin>
		</GlobalContext.Provider>
	);
};

export default DashboardTemplate(ApprovedController);
