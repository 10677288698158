import React, { useContext } from "react";
import DashboardTemplate from "../../../template/dashboard";
import { Row, Col, Statistic } from "antd";
import { UserOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../../helpers/global-context";

const DashboardView = () => {
	const { total } = useContext(GlobalContext);
	return (
		<Row gutter={16}>
			<Col lg={8} sm={24} xs={24}>
				<Statistic
					title={<h2>For Approval</h2>}
					prefix={<UserOutlined style={{ color: "#2BBB94" }} />}
					value={total.pending}
				/>
			</Col>
			<Col lg={8} sm={24} xs={24}>
				<Statistic
					title={<h2>Approved</h2>}
					prefix={<CheckOutlined style={{ color: "blue" }} />}
					value={total.approved}
				/>
			</Col>
			<Col lg={8} sm={24} xs={24}>
				<Statistic
					title={<h2>Denied</h2>}
					prefix={<CloseOutlined style={{ color: "red" }} />}
					value={total.denied}
				/>
			</Col>
		</Row>
	);
};

export default DashboardTemplate(DashboardView);
