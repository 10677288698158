import React, { useEffect, useState, useCallback, useContext } from "react";
import { GlobalContext, AppContext } from "../../../helpers/global-context";
import DashboardTemplate from "../../../template/dashboard";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DENIED } from "../../../graphql/queries";
import moment from "moment";
import { UPDATE_APPLICATION_STATUS } from "../../../graphql/muattions";
import { message, Spin, Form } from "antd";
import DeniedView from "./denied-view";
import { debounce } from "lodash";

const DeniedController = () => {
	const { municipality_code } = useContext(AppContext);
	const [offset, setOffset] = useState(0);
	const [form] = Form.useForm();
	const [dataSource, setDataSource] = useState([]);
	const [ascend, setAscend] = useState(true);
	const [total, setTotal] = useState(0);
	const [is_admin] = useState(
		localStorage.getItem("is_admin") === "0" ? null : false
	);
	const [forApproval, { data, loading }] = useLazyQuery(DENIED, {
		fetchPolicy: "no-cache",
	});
	const [where, setWhere] = useState({
		_and: {
			status: { _eq: "Denied" },
			municipality_code: { _eq: municipality_code },
			is_admin: { _eq: is_admin },
		},
	});

	const [updateApplicationStatus] = useMutation(UPDATE_APPLICATION_STATUS, {
		onCompleted(res) {
			message.success(
				`Application ${res.update_workpass_applications.returning[0].status}`
			);
			triggerQuery();
		},
	});

	const triggerQuery = () => {
		forApproval({
			variables: {
				where,
				offset,
				order: ascend ? "asc" : "desc",
			},
		});
	};

	const _debouncedSearch = useCallback(debounce(triggerQuery, 500), [
		where,
		offset,
		ascend,
	]);

	const _searchHandler = (e) => {
		const val = e.target.value;
		if (val.length) {
			setWhere({
				...where,
				_or: [
					{ first_name: { _ilike: `%${val}%` } },
					{ last_name: { _ilike: `%${val}%` } },
				],
			});
			setOffset(0);
		} else {
			const remove_where = { ...where };
			delete remove_where?._or;
			setWhere(remove_where);
			setOffset(0);
		}
	};

	const _onSearchHandler = (data) => {
		if (data.first_name || data.last_name) {
			setWhere({
				...where,
				first_name: { _ilike: `%${data?.first_name ?? ""}%` },
				last_name: { _ilike: `%${data?.last_name ?? ""}%` },
			});
			setOffset(0);
		} else {
			form.setFieldsValue({ first_name: null, last_name: null });
			const remove_where = { ...where };
			delete remove_where?.first_name;
			delete remove_where?.last_name;
			setWhere(remove_where);
			setOffset(0);
		}
	};

	useEffect(() => {
		_debouncedSearch();
	}, [offset, where, ascend]);

	useEffect(() => {
		if (data) {
			const items = data.workpass_applications.map((application) => {
				const {
					first_name,
					last_name,
					company_name,
					destination_barangay,
					destination_location,
					destination_municipality,
					destination_province,
					municipality_code,
					id,
					office_number,
					origin_barangay,
					origin_location,
					origin_municipality,
					origin_province,
					status,
					created_at,
					workpass_application_files,
				} = application;
				return {
					name: `${first_name} ${last_name}`,
					company: {
						company_name,
						office_number,
					},
					origin: {
						origin_barangay,
						origin_location,
						origin_municipality,
						origin_province,
					},
					destination: {
						destination_barangay,
						destination_location,
						destination_municipality,
						destination_province,
						municipality_code,
					},
					id,
					status,
					municipality_code,
					workpass_application_files,
					request_date: moment(created_at).format("LLL"),
					picture: workpass_application_files.filter(
						({ type }) => type === "picture"
					)?.[0]?.url,
				};
			});
			setDataSource(items);
			setTotal(data.workpass_applications_aggregate.aggregate.count);
		}
	}, [data]);

	const _offsetHandler = (page) => {
		setOffset((page - 1) * 5);
	};

	const _approveHandler = ({ id }) => {
		updateApplicationStatus({
			variables: {
				id,
				status: "Approved",
				is_admin: false,
			},
		});
	};

	const _denyHandler = ({ id }) => {
		updateApplicationStatus({
			variables: {
				id,
				status: "Denied",
				is_admin: false,
			},
		});
	};

	const _viewHandler = (data) => {
		console.log(data);
	};

	const _setHandler = ({ id }) => {
		updateApplicationStatus({
			variables: {
				id,
				status: "Approved",
				is_admin: true,
			},
		});
	};

	const values = {
		dataSource,
		offset,
		total,
		ascend,
		setAscend,
		setOffset,
		_offsetHandler,
		_approveHandler,
		_denyHandler,
		_viewHandler,
		_searchHandler,
		_setHandler,
		_onSearchHandler,
	};

	return (
		<GlobalContext.Provider value={values}>
			<Spin spinning={loading}>
				<DeniedView />
			</Spin>
		</GlobalContext.Provider>
	);
};

export default DashboardTemplate(DeniedController);
