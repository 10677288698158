import React, { useContext, useState, useEffect, useMemo } from "react";
import { GlobalContext } from "../../helpers/global-context";
import {
  Space,
  Popconfirm,
  message,
  Descriptions,
  Divider,
  Form,
  Input,
  Button,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import PDFViewer from "pdf-viewer-reactjs";
import { FilePdfOutlined } from "@ant-design/icons";
import _ from "lodash";
import axios from "axios";

const TableActions = ({ val }) => {
  const {
    _approveHandler,
    _denyHandler,
    _viewHandler,
    form,
    setApplicationId,
    showNotes,
    setShowNotes,
    setNotes,
    _viewIDHandler = null,
    _setHandler = null,
    _printIDHandler = null,
    onSearch = null,
  } = useContext(GlobalContext);
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [showFile, setShowFile] = useState(null);
  const [showPDF, setShowPDF] = useState(false);
  const [images, setImages] = useState(null);
  let id = null;

  const _onCancel = () => {
    message.error("Action cancelled");
  };

  const _pictureHandler = (url) => {
    setShowFile(true);
    setFile(url);
  };

  const _pdfHandler = (url) => {
    setShowPDF(true);
    setFile(url);
  };

  const getSignedImageUrl = async (key) => {
    let url = "";
    if (key?.length > 1) {
      key = key.replace("https://pasajob.s3.amazonaws.com/", "");
      let image = await axios.get("https://auth.pasajob.com/s3-signed-url", {
        params: {
          bucket: "pasajob",
          key,
        },
      });
      url = image.data;
      return url;
    } else {
      return url;
    }
  };

  useMemo(() => {
    if (val) {
      console.log("val", val);
      async function prepImages() {
        const certificate_of_employment = await getSignedImageUrl(
          _.filter(
            val.workpass_application_files,
            (o) => o.type === "certificate_of_employment"
          )[0].url
        );
        const picture = await getSignedImageUrl(
          _.filter(
            val.workpass_application_files,
            (o) => o.type === "picture"
          )[0].url
        );
        const company_id = await getSignedImageUrl(
          _.filter(
            val.workpass_application_files,
            (o) => o.type === "company_id"
          )[0].url
        );
        const medical_certificate = await getSignedImageUrl(
          _.filter(
            val.workpass_application_files,
            (o) => o.type === "medical_certificate"
          )[0].url
        );
        setImages({...images, certificate_of_employment, picture, company_id, medical_certificate})
      }
      prepImages();
    }
  }, [val]);

  return (
    <div>
      <Modal
        visible={showNotes}
        closable
        onCancel={() => setShowNotes(false)}
        onOk={_denyHandler}
      >
        <h4>Notes</h4>
        <Input.TextArea
          autoSize={{ minRows: 5, maxRows: 5 }}
          onChange={(e) => setNotes(e.target.value)}
        />
      </Modal>
      <Modal
        visible={showFile}
        onCancel={() => setShowFile(false)}
        closable
        footer={null}
        width={1000}
      >
        <img
          src={file}
          style={{ width: "100%", objectFit: "cover" }}
          alt="picture"
        />
      </Modal>
      <Modal
        visible={showPDF}
        onCancel={() => setShowPDF(false)}
        closable
        footer={null}
        width={1000}
      >
        <PDFViewer
          document={{
            url: file,
          }}
        />
      </Modal>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        closable
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={images?.picture}
            style={{ width: 150, height: 150, objectFit: "cover" }}
            alt="Profile Picture"
          />
        </div>
        <Divider orientation="center">Personal & Company Information</Divider>
        <Descriptions column={2}>
          <Descriptions.Item label="Name">{val.name}</Descriptions.Item>
          <Descriptions.Item label="Mobile Number">
            {val.mobile_number ?? ""}
          </Descriptions.Item>
          <Descriptions.Item label="Company name">
            {val.company.company_name}
          </Descriptions.Item>
          <Descriptions.Item label="Company number">
            {val.company.office_number}
          </Descriptions.Item>
        </Descriptions>
        <Divider orientation="center">Origin</Divider>
        <Descriptions column={2}>
          <Descriptions.Item label="Province">
            {val.origin.origin_province}
          </Descriptions.Item>
          <Descriptions.Item label="Municipality">
            {val.origin.origin_municipality}
          </Descriptions.Item>
          <Descriptions.Item label="Barangay">
            {val.origin.origin_barangay}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {val.origin.origin_location}
          </Descriptions.Item>
        </Descriptions>
        <Divider orientation="center">Destination</Divider>
        <Descriptions column={2}>
          <Descriptions.Item label="Province">
            {val.destination.destination_province}
          </Descriptions.Item>
          <Descriptions.Item label="Municipality">
            {val.destination.destination_municipality}
          </Descriptions.Item>
          <Descriptions.Item label="Barangay">
            {val.destination.destination_barangay}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {val.destination.destination_location}
          </Descriptions.Item>
        </Descriptions>
        <Divider orientation="center">Files</Divider>
        <h4>Medical Certificates</h4>
        {val.workpass_application_files
          .filter(({ type }) => type === "medical_certificate")
          .map(({ url }, index) => {
            let extension = url.split(".");
            extension = extension[extension.length - 1];
            return extension === "pdf" ? (
              <a
                key={`${index}`}
                onClick={_pdfHandler.bind(this, url)}
                style={{ marginRight: 5 }}
              >
                <FilePdfOutlined style={{ fontSize: 40, color: "red" }} />
              </a>
            ) : (
              <a
                key={`${index}`}
                onClick={_pictureHandler.bind(this, images?.medical_certificate)}
                style={{ marginRight: 5 }}
              >
                <img
                  src={images?.medical_certificate}
                  style={{ width: 70, height: 70, objectFit: "cover" }}
                  alt="Medical Certificate"
                />
              </a>
            );
          })}
        <h4>Company Identification Card(s)</h4>
        {val.workpass_application_files
          .filter(({ type }) => type === "company_id")
          .map(({ url }, index) => {
            let extension = url.split(".");
            extension = extension[extension.length - 1];
            return extension === "pdf" ? (
              <a
                key={`${index}`}
                onClick={_pdfHandler.bind(this, url)}
                style={{ marginRight: 5 }}
              >
                <FilePdfOutlined style={{ fontSize: 40, color: "red" }} />
              </a>
            ) : (
              <a
                key={`${index}`}
                onClick={_pictureHandler.bind(this,images?.company_id)}
                style={{ marginRight: 5 }}
              >
                <img
                  src={images?.company_id}
                  style={{ width: 70, height: 70, objectFit: "cover" }}
                  alt="Company Identification Card"
                />
              </a>
            );
          })}
        <h4>Certificate of Employment</h4>
        {val.workpass_application_files
          .filter(({ type }) => type === "certificate_of_employment")
          .map(({ url }, index) => {
            let extension = url.split(".");
            extension = extension[extension.length - 1];
            return extension === "pdf" ? (
              <a
                key={`${index}`}
                onClick={_pdfHandler.bind(this, url)}
                style={{ marginRight: 5 }}
              >
                <FilePdfOutlined style={{ fontSize: 40, color: "red" }} />
              </a>
            ) : (
              <a
                key={`${index}`}
                onClick={_pictureHandler.bind(this, images?.certificate_of_employment)}
                style={{ marginRight: 5 }}
              >
                <img
                  src={images?.certificate_of_employment}
                  style={{ width: 70, height: 70, objectFit: "cover" }}
                  alt="Certificate of Employment"
                />
              </a>
            );
          })}
      </Modal>
      {val.status === "Pending" ? (
        <Space size="middle">
          <Popconfirm
            onCancel={_onCancel.bind(this)}
            onConfirm={_approveHandler.bind(this, val)}
          >
            <a>Approve</a>
          </Popconfirm>
          <Popconfirm
            onCancel={_onCancel.bind(this)}
            onConfirm={() => {
              setShowNotes(true);
              setApplicationId(val.id);
              // id = val.id;
            }}
          >
            <a>Deny</a>
          </Popconfirm>
          {localStorage.getItem("is_admin") === "0" && (
            <Popconfirm
              onCancel={_onCancel.bind(this)}
              onConfirm={_setHandler.bind(this, val)}
            >
              <a>Set</a>
            </Popconfirm>
          )}
          <a onClick={() => setVisible(true)}>View</a>
        </Space>
      ) : val.status === "Approved" ? (
        <Space size="middle">
          <Popconfirm
            onCancel={_onCancel.bind(this)}
            onConfirm={() => {
              setShowNotes(true);
              setApplicationId(val.id);
              // id = val.id;
            }}
          >
            <a>Deny</a>
          </Popconfirm>
          <a onClick={() => setVisible(true)}>View</a>
          {localStorage.getItem("is_admin") === "0" && (
            <Popconfirm
              onCancel={_onCancel.bind(this)}
              onConfirm={_setHandler.bind(this, val)}
            >
              <a>Set</a>
            </Popconfirm>
          )}
          <a onClick={_viewIDHandler.bind(this, val)}>QR Pass</a>
          {!localStorage.getItem("municipality_code") && (
            <a onClick={onSearch.bind(this, val)}>MAP</a>
          )}
          {/* <a onClick={_printIDHandler.bind(this, val)}>Print QR</a> */}
        </Space>
      ) : (
        <Space size="middle">
          <Popconfirm
            onCancel={_onCancel.bind(this)}
            onConfirm={_approveHandler.bind(this, val)}
          >
            <a>Approve</a>
          </Popconfirm>
          {localStorage.getItem("is_admin") === "0" && (
            <Popconfirm
              onCancel={_onCancel.bind(this)}
              onConfirm={_setHandler.bind(this, val)}
            >
              <a>Set</a>
            </Popconfirm>
          )}
          <a onClick={() => setVisible(true)}>View</a>
        </Space>
      )}
    </div>
  );
};

export default TableActions;
