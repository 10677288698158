import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import { Row, Col } from "antd";

class IdentificationSmall extends React.Component {
	constructor(props) {
		super(props);
		const { info } = props;
		this.state = {
			info,
		};
	}

	render() {
		return (
			<div style={{ border: "50px 0 0 0 solid white", overflow: "auto" }}>
				<div style={{ width: 380 }}>
					<div
						style={{
							position: "relative",
							display: "block",
							width: "100%",
							backgroundImage: `url(${require("../../assets/background.jpg")})`,
							backgroundSize: "cover",
							height: 600,
							border: "solid 5px black",
							backgroundRepeat: "no-repeat",
						}}
						id="card"
					>
						<Row gutter={16}>
							<Col span={8}>
								<img
									src={require("../../assets/province-of-cebu-seal.png")}
									style={{
										width: "100%",
										objectFit: "contain",
									}}
									className="seal"
								/>
							</Col>
							<Col span={16}>
								<img
									src={require("../../assets/province-of-cebu-text.png")}
									style={{
										width: "100%",
										marginTop: 20,
										objectFit: "contain",
									}}
									className="seal"
								/>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<div
									style={{
										padding: 10,
										backgroundColor: "white",
										marginBottom: 20,
										textAlign: "center",
										textTransform: "uppercase",
										fontSize: 16,
									}}
								>
									{this.state?.info?.company?.company_name ?? "Company Name"}
								</div>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<div className="picture-big">
									<img
										src={
											this.state?.info?.workpass_application_files.filter(
												({ type }) => type === "picture"
											)?.[0]?.url
										}
										style={{
											width: 150,
											height: 150,
											objectFit: "contain",
											backgroundColor: "white",
										}}
										alt="picture"
										id="profile-picture"
									/>
								</div>
								<div className="picture-small">
									<img
										src={this.state?.info?.workpass_application_files[0].url}
										style={{
											width: 120,
											height: 120,
											objectFit: "contain",
											backgroundColor: "white",
										}}
										alt="picture"
										id="profile-picture"
									/>
								</div>
							</Col>
							<Col span={12}>
								<div className="qr-big">
									<QRCode
										value={this.state?.info?.unique_id ?? ""}
										size={150}
									/>
								</div>
								<div className="qr-small">
									<QRCode
										value={this.state?.info?.unique_id ?? ""}
										size={120}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<div className="control-no">
									{`Control No. ${this.state?.info?.municipality_code.substring(
										0,
										this.state?.info?.municipality_code.length - 4
									)}${("00000" + this.state?.info?.id).slice(-5)}`}
								</div>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<div className="full-name">{`${this.state?.info?.name}`}</div>
							</Col>
						</Row>
						<div style={{ backgroundColor: "red", padding: 3 }}>
							<h3 style={{ color: "white", textAlign: "center", fontSize: 16 }}>
								VALID ONLY FOR TRAVEL
							</h3>
							<div className="to-from">
								<Row gutter={16}>
									<Col span={7} style={{ paddingLeft: 20 }}>
										TO:
									</Col>
									<Col span={17}>
										{this.state?.info?.destination?.destination_municipality ??
											"Cebu City"}
									</Col>
								</Row>
							</div>
							<div className="to-from">
								<Row gutter={16}>
									<Col span={7} style={{ paddingLeft: 20 }}>
										FROM:
									</Col>
									<Col span={17}>
										{this.state?.info?.origin?.origin_municipality ??
											"Cebu City"}
									</Col>
								</Row>
							</div>
							<h3 style={{ color: "white", textAlign: "center", fontSize: 16 }}>
								AND VICE VERSA
							</h3>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default IdentificationSmall;
