import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RegistrationpageController from "../screens/registration-page/registrationpage-controller";
import makeApolloClient from "../helpers/client";
import { ApolloProvider } from "@apollo/client";
import LoginController from "../screens/login/login-controller";
import { AppContext } from "../helpers/global-context";
import Authenticated from "./authenticated";
import DashboardController from "../screens/management/dashboard/dashboard-controller";
import { LOGIN } from "../helpers/services";
import { useCookies } from "react-cookie";
import ForApprovalController from "../screens/management/for-approval/for-approval-controller";
import ApprovedController from "../screens/management/approved/approved-controller";
import DeniedController from "../screens/management/denied/denied-controller";
import LogoutController from "../screens/management/logout/logout-controller";
import ReportsController from "../screens/management/reports/reports-controller";
import AllController from "../screens/management/all/all-controller";

const AppRouter = () => {
	const code = localStorage.getItem("municipality_code");
	const [client, setClient] = useState(null);
	const [cookies, setCookie, removeCookie] = useCookies(["name"]);
	const [token, setToken] = useState(null);
	const [municipality_code] = useState(code ?? null);

	const generateToken = async () => {
		const generatedToken = await LOGIN({
			username: process.env.REACT_APP_USERNAME,
			password: process.env.REACT_APP_PASSWORD,
		});
		const client = makeApolloClient(generatedToken);
		setClient(client);
	};

	useEffect(() => {
		if (token) {
			const client = makeApolloClient(token);
			setClient(client);
		}
	}, [token]);

	useEffect(() => {
		if (!client) {
			if (cookies.token) {
				const client = makeApolloClient(cookies.token);
				setClient(client);
			} else {
				generateToken();
			}
		}
	}, [client, cookies]);

	const _logoutHandler = () => {
		console.log("logout");
	};

	const values = {
		token,
		cookies,
		municipality_code,
		setCookie,
		removeCookie,
		setToken,
		_logoutHandler,
	};

	if (!client) return null;

	return (
		<Router>
			<ApolloProvider client={client}>
				<AppContext.Provider value={values}>
					<Switch>
						{/* <Route path="/" exact component={HomepageController} />
						<Route
							path="/work-pass-status"
							exact
							component={StatusController}
						/> */}
						<Authenticated
							path="/work-pass"
							exact
							component={RegistrationpageController}
						/>

						<Route path="/" exact component={LoginController} />
						<Authenticated
							path="/lgu-dashboard"
							exact
							component={DashboardController}
						/>
						<Authenticated
							path="/lgu-for-approval"
							exact
							component={ForApprovalController}
						/>
						<Authenticated
							path="/lgu-approved"
							exact
							component={ApprovedController}
						/>
						<Authenticated
							path="/lgu-denied"
							exact
							component={DeniedController}
						/>
						<Authenticated
							path="/lgu-dxform-all"
							exact
							component={AllController}
						/>
						<Authenticated
							path="/reports"
							exact
							component={ReportsController}
						/>
						<Authenticated path="/logout" exact component={LogoutController} />
					</Switch>
				</AppContext.Provider>
			</ApolloProvider>
		</Router>
	);
};

export default AppRouter;
