import React, { useContext } from "react";
import { Form, Divider, Button, Upload, Alert, Modal, Row, Col } from "antd";
import { GlobalContext } from "../../helpers/global-context";
import { CameraOutlined } from "@ant-design/icons";
import Webcam from "react-webcam";

const Step4 = () => {
	const {
		_onForthStepHandler,
		_onErrorHandler,
		_medicalCertificateHandler,
		_removeImage,
		_companyIdsHandler,
		_certificateOfEmploymentHandler,
		_cameraHandler,
		_takePictureHandler,
		certificates,
		_picturesHandler,
		ids,
		employment_certificates,
		_backHandler,
		loading,
		error,
		showCamera,
		webcamRef,
		picture,
		form,
		pictures,
	} = useContext(GlobalContext);

	console.log(pictures);

	return (
		<Form
			layout="vertical"
			onFinish={_onForthStepHandler}
			onFinishFailed={_onErrorHandler}
			form={form}
		>
			{error && (
				<Alert
					message="File Invalid"
					type="error"
					description={`Valid format JPG, PNG and PDF.\r\nFile size below 4 MB.`}
					closable={true}
				/>
			)}
			<Divider orientation="left">Files</Divider>
			<h3>
				Medical Certificate(s){" "}
				<small>(format: PNG, JPG and PDF | file size: less than 4MB)</small>
			</h3>
			<Upload
				listType="picture-card"
				showUploadList={true}
				beforeUpload={_medicalCertificateHandler}
				onRemove={_removeImage}
				fileList={certificates}
			>
				+ Upload
			</Upload>
			<h3>
				Company Identification Card(s){" "}
				<small>(format: PNG, JPG and PDF | file size: less than 4MB)</small>
			</h3>
			<Upload
				listType="picture-card"
				showUploadList={true}
				fileList={ids}
				beforeUpload={_companyIdsHandler}
				onRemove={_removeImage}
			>
				+ Upload
			</Upload>
			<h3>
				Certificate of Employment{" "}
				<small>(format: PNG, JPG and PDF | file size: less than 4MB)</small>
			</h3>
			<Upload
				listType="picture-card"
				showUploadList={true}
				fileList={employment_certificates}
				beforeUpload={_certificateOfEmploymentHandler}
				onRemove={_removeImage}
			>
				+ Upload
			</Upload>
			<h3>
				Picture <small>(take a selfie | upload format: JPG and PNG)</small>
			</h3>
			<Upload
				showUploadList={true}
				fileList={pictures}
				listType="picture-card"
				beforeUpload={_picturesHandler}
				onRemove={_removeImage}
			>
				+ Upload
			</Upload>
			<Button icon={<CameraOutlined />} size="large" onClick={_cameraHandler}>
				Take a Picture
			</Button>
			<Modal visible={showCamera} footer={null} onCancel={_cameraHandler}>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Webcam
						audio={false}
						height={450}
						ref={webcamRef}
						screenshotFormat="image/jpeg"
						width={400}
						mirrored={true}
						screenshotQuality={0.5}
						videoConstraints={{ facingMode: "user" }}
					/>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Button
						shape="circle-outline"
						icon={<CameraOutlined />}
						size="large"
						type="primary"
						onClick={_takePictureHandler}
					/>
				</div>
			</Modal>
			<div style={{ marginTop: 40, marginBottom: 40 }}>
				<Alert
					type="warning"
					description="Please make sure all submitted information is correct."
				/>
			</div>
			<Row gutter={16}>
				<Col span={12}>
					<Button
						type="primary"
						htmlType="button"
						block
						size="large"
						onClick={() => _backHandler("step_3")}
						ghost
					>
						BACK
					</Button>
				</Col>
				<Col span={12}>
					<Button
						type="primary"
						htmlType="submit"
						block
						size="large"
						disabled={
							certificates.length === 0 ||
							ids.length === 0 ||
							employment_certificates.length === 0 ||
							pictures.length === 0
						}
						loading={loading}
					>
						Submit
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

export default Step4;
