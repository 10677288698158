import React, { useContext } from "react";
import { Form, Input, Divider, Button, Modal, Row, Col } from "antd";
import { VALIDATION } from "../../helpers/rules";
import { GlobalContext } from "../../helpers/global-context";
import QrReader from "react-qr-reader";

const Step1 = () => {
	const {
		form,
		_onFinishHandler,
		_onErrorHandler,
		state,
		_qrHandler,
		showQR,
		_scanHandler,
		_generateQRHandler,
	} = useContext(GlobalContext);

	return (
		<Form
			layout="vertical"
			onFinish={_onFinishHandler}
			onFinishFailed={_onErrorHandler}
			form={form}
		>
			<Divider orientation="left">{`Personal & Company Information`}</Divider>
			<Form.Item
				name="first_name"
				label={<h3>First name</h3>}
				rules={VALIDATION.FIRSTNAME}
				initialValue={state.first_name}
			>
				<Input size="large" />
			</Form.Item>
			<Form.Item
				name="last_name"
				label={<h3>Last name</h3>}
				rules={VALIDATION.LASTNAME}
				initialValue={state.last_name}
			>
				<Input size="large" />
			</Form.Item>
			<Form.Item
				name="mobile_number"
				label={<h3>Mobile/Contact Number</h3>}
				rules={VALIDATION.MOBILE_NUMBER}
				initialValue={state.mobile_number}
			>
				<Input size="large" />
			</Form.Item>
			<Form.Item
				name="company_name"
				label={<h3>Company name</h3>}
				rules={VALIDATION.COMPANY_NAME}
				initialValue={state.company_name}
			>
				<Input size="large" />
			</Form.Item>
			<Form.Item
				name="office_number"
				label={<h3>Company contact number</h3>}
				rules={VALIDATION.OFFICE_NUMBER}
				initialValue={state.office_number}
			>
				<Input size="large" />
			</Form.Item>
			<h3>
				QR code{" "}
				<small>
					{`(QR code can be found in WeTrace App -> Device Information)`}
				</small>
			</h3>
			<Row gutter={0}>
				<Col span={18}>
					<Form.Item
						rules={VALIDATION.QR}
						name="unique_id"
						style={{ width: "100%" }}
					>
						<Input.Search
							size="large"
							enterButton="SCAN"
							onSearch={_qrHandler}
						/>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Button
						type="primary"
						danger
						size="large"
						onClick={_generateQRHandler}
						htmlType="button"
					>
						GENERATE
					</Button>
				</Col>
			</Row>

			<Modal visible={showQR} footer={null} onCancel={_qrHandler}>
				<QrReader
					delay={300}
					style={{ width: "100%" }}
					onScan={_scanHandler}
					onError={_onErrorHandler}
				/>
			</Modal>
			<div>
				<Button type="primary" htmlType="submit" block size="large">
					NEXT
				</Button>
			</div>
		</Form>
	);
};

export default Step1;
