import React from "react";
import moment from "moment";
import { Timeline } from "antd";

const Activity = ({ data, recenter, userPlace }) => {
	let collision = [];

	if (data && data.length) {
		collision = data.map((search_collision, index) => {
			return (
				<div>
					<div>
						<b>{index + 1}</b>. {search_collision.app_user_unique_id}
					</div>
					<div>{moment(search_collision.created_at).format("hh:mm:ss a")}</div>
				</div>
			);
		});
		collision.unshift(
			<div style={{ marginTop: "10px" }}>
				<i>
					<b>Collision Detected</b>
				</i>
			</div>
		);
	}

	return (
		<Timeline.Item
			color={collision.length ? "red" : "blue"}
			style={{ cursor: "pointer" }}
			onClick={recenter(userPlace.place)}
		>
			<div style={{ marginBottom: "20px" }}>
				<div>
					<b>
						<i>{moment(userPlace.place.created_at).format("YYYY-MM-DD")}</i>
					</b>
				</div>
				<div>LAT: {userPlace.place.lat}</div>
				<div>LNG: {userPlace.place.lng}</div>
			</div>
			<b>
				<i>Station Time</i>
			</b>
			{userPlace.stationary.map((stationary) => {
				return <div>{moment(stationary.created_at).format("hh:mm:ss a")}</div>;
			})}
			{collision}
		</Timeline.Item>
	);
};

export default Activity;
