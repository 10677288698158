import { useContext, useEffect } from "react";
import { AppContext } from "../../../helpers/global-context";

const LogoutController = () => {
	const { removeCookie } = useContext(AppContext);
	useEffect(() => {
		removeCookie("token");
		localStorage.clear();
		window.location.reload();
	}, [removeCookie]);
	return null;
};

export default LogoutController;
