import React, { useEffect, useState, useContext } from "react";
import { GlobalContext, AppContext } from "../../../helpers/global-context";
import DashboardView from "./dashboard-view";
import { useLazyQuery } from "@apollo/client";
import { TOTAL } from "../../../graphql/queries";
import { Spin } from "antd";

const DashboardController = () => {
	const { municipality_code } = useContext(AppContext);
	const [total, setTotal] = useState({
		pending: 0,
		approved: 0,
		denied: 0,
	});
	const [queryTotal, { data, loading }] = useLazyQuery(TOTAL, {
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		if (localStorage.getItem("municipality_code")) {
			queryTotal({
				variables: {
					municipality_code,
					is_admin: false,
				},
			});
		} else if (localStorage.getItem("is_admin") === "0") {
			queryTotal({
				variables: {
					municipality_code,
				},
			});
		} else {
			queryTotal({
				variables: {
					municipality_code,
					is_admin: false,
				},
			});
		}
	}, [queryTotal, municipality_code]);

	useEffect(() => {
		if (data) {
			const {
				pending: {
					aggregate: { count: pending },
				},
				approved: {
					aggregate: { count: approved },
				},
				denied: {
					aggregate: { count: denied },
				},
			} = data;
			setTotal({
				pending,
				approved,
				denied,
			});
		}
	}, [data]);

	const values = { total };

	return (
		<GlobalContext.Provider value={values}>
			<Spin spinning={loading}>
				<DashboardView />
			</Spin>
		</GlobalContext.Provider>
	);
};

export default DashboardController;
