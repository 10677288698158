import React, { useEffect } from "react";
import "./App.css";
import AppRouter from "./router";
import "antd/dist/antd.css";

import { useClearCache } from "react-clear-cache";

function App() {
	// const { isLatestVersion, emptyCacheStorage } = useClearCache();

	// useEffect(() => {
	// 	if (!isLatestVersion) {
	// 		localStorage.clear();
	// 		emptyCacheStorage();
	// 		window.location.reload();
	// 	}
	// }, [isLatestVersion, emptyCacheStorage]);

	return <AppRouter />;
}

export default App;
