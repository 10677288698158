import React, { useContext } from "react";
import DataTable from "../../components/table";
import { GlobalContext } from "../../../helpers/global-context";
import { PageHeader, Modal, Button } from "antd";
import IdentificationNew from "../../components/identification-pass";
import IdentificationSmall from "../../components/identification-small";

const AllView = () => {
	const {
		dataSource,
		showID,
		info,
		print,
		setShowID,
		setInfo,
		printRef,
		_printHandler,
	} = useContext(GlobalContext);

	return (
		<>
			<Modal
				visible={showID}
				closable={true}
				onCancel={() => {
					setShowID(false);
					setInfo(null);
				}}
				footer={null}
				width={440}
			>
				{info && <IdentificationSmall info={info} ref={printRef} />}
				{print && <IdentificationSmall info={print} ref={printRef} />}
				<div style={{ textAlign: "center", marginTop: 30 }}>
					<Button size="large" type="primary" onClick={_printHandler}>
						PRINT ID
					</Button>
				</div>
			</Modal>
			<PageHeader title="All Applications" />
			<DataTable dataSource={dataSource} />
		</>
	);
};

export default AllView;
