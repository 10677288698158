import React, { useContext, useEffect } from "react";
import { AppContext } from "../helpers/global-context";
import { Redirect, Route } from "react-router-dom";
import { withCookies } from "react-cookie";
import { useClearCache } from "react-clear-cache";
import { useMutation } from "@apollo/client";
import {
	UPDATE_OSLOB,
	UPDATE_ALCANTARA,
	REMOVE_APPLICATION_WITHOUT_FILES,
} from "../graphql/muattions";

const Authenticated = (props) => {
	const { cookies } = useContext(AppContext);
	const { isLatestVersion, emptyCacheStorage } = useClearCache();
	const [updateOslob] = useMutation(UPDATE_OSLOB);
	const [updateAlcantara] = useMutation(UPDATE_ALCANTARA);
	const [denyApplications] = useMutation(REMOVE_APPLICATION_WITHOUT_FILES);

	useEffect(() => {
		if (!isLatestVersion) {
			emptyCacheStorage();
			window.location.reload();
		}
	}, [isLatestVersion, emptyCacheStorage]);

	useEffect(() => {
		updateOslob();
		updateAlcantara();
		denyApplications();
	}, []);

	if (!isLatestVersion) {
		return <Redirect to="/logout" />;
	}

	return cookies.token ? <Route {...props} /> : <Redirect to="/" />;
};

export default withCookies(Authenticated);
