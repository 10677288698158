import React, { useContext } from "react";
import { GlobalContext } from "../../../helpers/global-context";
import { Table, Input, Row, Col, Button } from "antd";
import moment from "moment";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ReportsView = () => {
	const { reports, _filterHandler } = useContext(GlobalContext);

	const columns = [
		{
			title: "Municipality",
			dataIndex: "municipality",
			id: "municipality",
			sorter: (a, b) => a.municipality.localeCompare(b.municipality),
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Approved",
			dataIndex: "approved",
			id: "approved",
			sorter: (a, b) => a.approved - b.approved,
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Denied",
			dataIndex: "denied",
			id: "denied",
			sorter: (a, b) => a.denied - b.denied,
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Pending",
			dataIndex: "pending",
			id: "pending",
			sorter: (a, b) => a.pending - b.pending,
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Total",
			dataIndex: "total",
			id: "total",
			sorter: (a, b) => a.total - b.total,
			sortDirections: ["descend", "ascend"],
		},
	];

	return (
		<div>
			<Row gutter={16} style={{ marginBottom: 10 }}>
				<Col span={4}>
					<Input
						onChange={_filterHandler}
						size="large"
						placeholder="Search by Municipality"
						allowClear={true}
					/>
				</Col>
				<Col span={4}>
					<ExcelFile
						element={
							<Button type="primary" size="large">
								DOWNLOAD EXCEL
							</Button>
							// <div className="px-5 py-1 mr-5 cursor-pointer bg-green-700 rounded-md text-white" style={{ paddingTop: 5, paddingBottom: 5 }}>
							//     DOWNLOAD EXCEL
							// </div>
						}
						filename={`Work Pass Report - ${moment().format("LLL")}`}
					>
						<ExcelSheet data={reports} name="WORK PASSES">
							<ExcelColumn label="Municipality" value="municipality" />
							<ExcelColumn label="For Approval" value="pending" />
							<ExcelColumn label="Approved" value="approved" />
							<ExcelColumn label="Denied" value="denied" />
							<ExcelColumn label="Total" value="total" />
						</ExcelSheet>
					</ExcelFile>
				</Col>
			</Row>
			<Table dataSource={reports} columns={columns} />
		</div>
	);
};

export default ReportsView;
