import React, { useState, useContext, useMemo } from "react";
import { Row, Col, Button } from "antd";
import { GlobalContext } from "../../helpers/global-context";
import Step1 from "./step-1";
import Step2 from "./step-2";
import Step3 from "./step-3";
import Step4 from "./step-4";
import Identification from "../components/identification";
import axios from "axios";

const RegistrationpageView = () => {
  const {
    steps,
    success,
    info,
    _clickHandler,
    printRef,
    _printHandler,
  } = useContext(GlobalContext);

  const getSignedImageUrl = async (key) => {
    let url = "";
    if (key?.length > 1) {
      key = key.replace("https://pasajob.s3.amazonaws.com/", "");
      let image = await axios.get("https://auth.pasajob.com/s3-signed-url", {
        params: {
          bucket: "pasajob",
          key,
        },
      });
      url = image.data;
      return url;
    } else {
      return url;
    }
  };

  const [newInfo, setNewInfo] = useState(null);

  useMemo(() => {
    if (info) {
      async function prepImages() {
        const picture = await getSignedImageUrl(
          info?.workpass_application_files[0].url
        );
        setNewInfo({ ...info, picture });
        console.log("info", info);
        console.log("picture", picture);
      }
      prepImages();
    }
  }, [info]);

  return (
    <div style={{ padding: "20px 0" }}>
      <Row gutter={16}>
        <Col lg={6} xs={1}></Col>
        <Col lg={12} xs={22}>
          <div className="space-align-block">
            <h1 style={{ textAlign: "center" }}>APPLICATION FORM</h1>
            <Row gutter={16} style={{ marginTop: 50 }}>
              <Col lg={4} sm={1} xs={1} />
              <Col lg={16} sm={22} xs={22}>
                {!success && (
                  <div>
                    <h2>
                      Step{" "}
                      {Object.keys(steps)
                        .filter((step) => steps[step] === true)[0]
                        .substring(6, 5)}{" "}
                      of 4
                    </h2>
                    {steps.step_1 && <Step1 />}
                    {steps.step_2 && <Step2 />}
                    {steps.step_3 && <Step3 />}
                    {steps.step_4 && <Step4 />}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {success && (
        <div>
          {newInfo !== null && <Identification info={newInfo} ref={printRef} />}
          <div
            style={{
              display: "block",
              marginTop: 50,
              textAlign: "center",
            }}
          >
            <Button type="primary" size="large" onClick={_clickHandler}>
              DOWNLOAD ID
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={_printHandler.bind(this)}
              style={{ marginLeft: 5 }}
            >
              PRINT ID
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegistrationpageView;
