import React, { useContext, useState } from "react";
import {
	Table,
	Descriptions,
	Pagination,
	Input,
	Row,
	Col,
	Form,
	Button,
	Tag,
} from "antd";
import { GlobalContext } from "../../helpers/global-context";
import TableActions from "./table-actions";
const DataTable = (props) => {
	const {
		_offsetHandler,
		total,
		_searchHandler,
		ascend,
		setAscend,
		form,
		_onSearchHandler,
	} = useContext(GlobalContext);
	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Company",
			dataIndex: "company",
			key: "company",
			width: 200,
			render: (val) => (
				<Descriptions column={1}>
					<Descriptions.Item label="Name">{val.company_name}</Descriptions.Item>
					<Descriptions.Item label="Office Number">
						{val.office_number}
					</Descriptions.Item>
				</Descriptions>
			),
		},
		{
			title: "Origin",
			key: "origin",
			dataIndex: "origin",
			width: 250,
			render: (val) => (
				<Descriptions column={1}>
					<Descriptions.Item label="Province">
						{val.origin_province}
					</Descriptions.Item>
					<Descriptions.Item label="Municipality">
						{val.origin_municipality}
					</Descriptions.Item>
					<Descriptions.Item label="Barangay">
						{val.origin_barangay}
					</Descriptions.Item>
					<Descriptions.Item label="Address">
						{val.origin_location}
					</Descriptions.Item>
				</Descriptions>
			),
		},
		{
			title: "Destination",
			dataIndex: "destination",
			key: "destination",
			width: 250,
			render: (val) => (
				<Descriptions column={1}>
					<Descriptions.Item label="Province">
						{val.destination_province}
					</Descriptions.Item>
					<Descriptions.Item label="Municipality">
						{val.destination_municipality}
					</Descriptions.Item>
					<Descriptions.Item label="Barangay">
						{val.destination_barangay}
					</Descriptions.Item>
					<Descriptions.Item label="Address">
						{val.destination_location}
					</Descriptions.Item>
				</Descriptions>
			),
		},
		{
			title: "Municipality Code",
			key: "municipality_code",
			dataIndex: "municipality_code",
		},
		{
			title: "Date Requested",
			key: "request_date",
			dataIndex: "request_date",
			sorter: ascend ? "ascend" : "descend",
			sortOrder: ascend ? "ascend" : "descend",
			sortDirections: ["ascend", "descend"],
			onHeaderCell: (column) => {
				return {
					onClick: () => {
						setAscend(!ascend);
					},
				};
			},
		},
	];

	if (!localStorage.getItem("municipality_code")) {
		columns.push(
			{
				title: "Status",
				key: "stauts",
				dataIndex: "status",
				render: (val) => (
					<Tag
						color={
							val === "Approved"
								? "green"
								: val === "Pending"
								? "orange"
								: "red"
						}
					>
						{val}
					</Tag>
				),
			},
			{
				title: "Requirements",
				key: "requirements",
				dataIndex: "workpass_application_files",
				render: (val) => {
					const iscomplete = val.filter(({ type }) =>
						[
							"picture",
							"company_id",
							"certificate_of_employment",
							"medical_certificate",
						].includes(type)
					);
					return (
						<Tag color={iscomplete.length >= 4 ? "green" : "red"}>
							{iscomplete.length >= 4 ? "Complete" : "Incomplete"}
						</Tag>
					);
				},
			},
			{
				title: "Actions",
				key: "actions",
				width: 200,
				render: (val) => <TableActions val={val} />,
			}
		);
	} else {
		columns.push({
			title: "Actions",
			key: "actions",
			width: 200,
			render: (val) => <TableActions val={val} />,
		});
	}

	return (
		<>
			{/* <Row gutter={16} style={{ marginBottom: 10 }}>
				<Col span={4}>
					<Input
						onChange={_searchHandler}
						placeholder="First name"
						size={"large"}
						allowClear={true}
					/>
				</Col>
				<Col span={4}>
					<Input
						onChange={_searchHandler}
						placeholder="Last name"
						size={"large"}
						allowClear={true}
					/>
				</Col>
			</Row> */}
			<Form
				layout="inline"
				style={{ marginBottom: 10 }}
				onFinish={_onSearchHandler}
				form={form}
			>
				<Form.Item name="first_name">
					<Input placeholder="First name" size={"large"} allowClear={true} />
				</Form.Item>
				<Form.Item name="last_name">
					<Input placeholder="Last name" size={"large"} allowClear={true} />
				</Form.Item>
				<Button htmlType="submit" size="large" style={{ marginRight: 10 }}>
					SEARCH
				</Button>
				<Button
					htmlType="button"
					onClick={_onSearchHandler.bind(this, {
						first_name: null,
						last_name: null,
					})}
					size="large"
				>
					Reset
				</Button>
			</Form>
			<Table columns={columns} {...props} pagination={false} />
			<div style={{ marginTop: 20 }}>
				<Pagination
					defaultCurrent={1}
					total={total}
					pageSize={5}
					onChange={_offsetHandler}
				/>
			</div>
		</>
	);
};

export default DataTable;
