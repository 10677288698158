const { gql } = require("@apollo/client");

const INSERT_APPLICATION = gql`
	mutation insert_workpass_application(
		$data: [workpass_applications_insert_input!]!
	) {
		insert_workpass_applications(objects: $data) {
			returning {
				id
				company_name
				destination_barangay
				destination_location
				destination_municipality
				destination_province
				first_name
				municipality_code
				office_number
				origin_barangay
				origin_location
				origin_municipality
				origin_province
				status
				unique_id
				workpass_application_files(where: { type: { _eq: "picture" } }) {
					url
				}
				last_name
			}
		}
	}
`;

const INSERT_USER = gql`
	mutation insert_workpass_application(
		$data: [workpass_applications_insert_input!]!
		$unique_id: String
	) {
		insert_mobile_app_users(objects: { unique_installation_id: $unique_id }) {
			returning {
				id
			}
		}
		insert_workpass_applications(objects: $data) {
			returning {
				id
				company_name
				destination_barangay
				destination_location
				destination_municipality
				destination_province
				first_name
				municipality_code
				office_number
				origin_barangay
				origin_location
				origin_municipality
				origin_province
				status
				unique_id
				workpass_application_files(where: { type: { _eq: "picture" } }) {
					url
				}
				last_name
			}
		}
	}
`;

const LOGIN_USER = gql`
	mutation map_login($username: String!, $password: String!) {
		map_login(object: { username: $username, password: $password }) {
			token
		}
	}
`;

const UPDATE_APPLICATION_STATUS = gql`
	mutation update_application_status(
		$id: Int
		$status: String
		$notes: String
		$is_admin: Boolean
	) {
		update_workpass_applications(
			where: { id: { _eq: $id } }
			_set: { status: $status, notes: $notes, is_admin: $is_admin }
		) {
			affected_rows
			returning {
				id
				status
				unique_id
			}
		}
	}
`;

const UPDATE_STATUS_BY_UNIQUE_ID = gql`
	mutation update_status($unique_id: String, $id: Int) {
		update_workpass_applications(
			where: {
				unique_id: { _eq: $unique_id }
				status: { _neq: "Approved" }
				id: { _lt: $id }
			}
			_set: { status: "Denied" }
		) {
			affected_rows
		}
	}
`;

const UPDATE_OSLOB = gql`
	mutation {
		update_workpass_applications(
			where: {
				origin_municipality: { _eq: "OSLOB" }
				municipality_code: { _eq: "D1-01-0001" }
			}
			_set: { municipality_code: "D2-05-0001" }
		) {
			affected_rows
		}
	}
`;

const UPDATE_ALCANTARA = gql`
	mutation {
		update_workpass_applications(
			where: {
				origin_municipality: { _eq: "ALCANTARA" }
				municipality_code: { _eq: "D1-01-0001" }
			}
			_set: { municipality_code: "D7-06-0001" }
		) {
			affected_rows
		}
	}
`;

const REMOVE_APPLICATION_WITHOUT_FILES = gql`
	mutation application_files {
		update_workpass_applications(
			where: {
				_or: [
					{ _not: { workpass_application_files: { type: { _eq: "picture" } } } }
					{
						_not: {
							workpass_application_files: {
								type: { _eq: "medical_certificate" }
							}
						}
					}
					{
						_not: {
							workpass_application_files: { type: { _eq: "company_id" } }
						}
					}
					{
						_not: {
							workpass_application_files: {
								type: { _eq: "certificate_of_employment" }
							}
						}
					}
				]
				status: { _eq: "Pending" }
			}
			_set: {
				status: "Denied"
				notes: "For denied applications, you can apply again but make sure all submitted documents are complete and correct. You can also coordinate with your LGU via phone or their social media channels."
			}
		) {
			returning {
				id
			}
		}
	}
`;

export {
	INSERT_APPLICATION,
	LOGIN_USER,
	UPDATE_APPLICATION_STATUS,
	INSERT_USER,
	UPDATE_STATUS_BY_UNIQUE_ID,
	UPDATE_OSLOB,
	UPDATE_ALCANTARA,
	REMOVE_APPLICATION_WITHOUT_FILES,
};
