import React, { useContext } from "react";
import { Row, Col, Card, Button, Form, Input, Alert, Spin } from "antd";
import { Link } from "react-router-dom";
import { VALIDATION } from "../../helpers/rules";
import { GlobalContext } from "../../helpers/global-context";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const LoginView = () => {
	const { _finishHandler, error, spin } = useContext(GlobalContext);

	return (
		<div className="space-align-container">
			<Row gutter={16}>
				<Col lg={8} xs={0}></Col>
				<Col lg={8} xs={24} style={{ marginTop: 50 }}>
					<Spin spinning={spin}>
						<div className="space-align-block">
							<Card style={{ paddingBottom: 30 }}>
								<Row>
									<Col lg={6} sm={1}></Col>
									<Col lg={12} sm={22}>
										<Link to="/">
											<img
												src={require("../../assets/app-logo.png")}
												style={{ width: "100%" }}
												alt="WeTrace App"
											/>
										</Link>
									</Col>
									<Col lg={6} sm={1}></Col>
								</Row>
								<h1 style={{ textAlign: "center" }}>LGU LOGIN</h1>

								<Row gutter={16} style={{ marginTop: 40 }}>
									<Col lg={3} sm={0} xs={0} />
									<Col lg={18} sm={24} xs={24}>
										{error && (
											<div style={{ margin: "20px 0" }}>
												<Alert
													type="error"
													description="Invalid username or password"
												/>
											</div>
										)}
										<Form layout="vertical" onFinish={_finishHandler}>
											<Form.Item
												name="username"
												label={<h3>Username</h3>}
												rules={VALIDATION.USERNAME}
											>
												<Input size="large" />
											</Form.Item>
											<Form.Item
												name="password"
												label={<h3>Password</h3>}
												rules={VALIDATION.PASSWORD}
											>
												<Input.Password
													size="large"
													iconRender={(visible) =>
														visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
													}
												/>
											</Form.Item>
											<Button
												style={{ backgroundColor: "#2BBB94", color: "white" }}
												htmlType="submit"
												size="large"
											>
												LOGIN
											</Button>
										</Form>
									</Col>
									<Col lg={3} sm={0} xs={0} />
								</Row>
							</Card>
						</div>
					</Spin>
				</Col>
				<Col lg={8} xs={0}></Col>
			</Row>
		</div>
	);
};

export default LoginView;
