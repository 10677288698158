import React, { useEffect, useState, useCallback } from "react";
import { GlobalContext } from "../../../helpers/global-context";
import ReportsView from "./reports-view";
import DashboardTemplate from "../../../template/dashboard";
import * as locations from "../../../helpers/locations.json";
import { useQuery } from "@apollo/client";
import { REPORTS } from "../../../graphql/queries";
import { Spin } from "antd";
import _ from "lodash";

const ReportsController = () => {
	const [reports, setReports] = useState(null);
	const [holder, setHolder] = useState(null);
	const [loading, setLoading] = useState(true);
	const { data } = useQuery(REPORTS, {
		fetchPolicy: "cache-and-network",
	});

	useEffect(() => {
		let { philippine_regions } = locations.data;
		let { philippine_provinces = [] } = philippine_regions.filter(
			({ region_code }) => region_code === "07"
		)?.[0];
		// .filter(({ province_code }) => province_code === "0722");
		const { philippine_cities = [] } = philippine_provinces.filter(
			({ province_code }) => province_code === "0722"
		)[0];
		if (data && philippine_cities.length) {
			const { workpass_applications } = data;
			let items = philippine_cities.map(
				({ city_municipality_code, city_municipality_description }) => {
					const total = workpass_applications.filter(
						({ municipality_code }) =>
							municipality_code === city_municipality_code
					);
					return {
						municipality: city_municipality_description,
						total: total.length,
						approved: total.filter(({ status }) => status === "Approved")
							.length,
						pending: total.filter(({ status }) => status === "Pending").length,
						denied: total.filter(({ status }) => status === "Denied").length,
					};
				}
			);
			setLoading(false);
			setReports(items);
			setHolder(items);
		}
	}, [locations, data]);

	const _filterByMunicipality = (text) => {
		if (text) {
			const items = _.filter(holder, function (item) {
				return item.municipality.toLowerCase().indexOf(text.toLowerCase()) > -1;
			});
			setReports(items);
		} else {
			setReports([...holder]);
		}
	};

	const filter = useCallback(_.debounce(_filterByMunicipality, 500), [reports]);

	const _filterHandler = (e) => {
		filter(e.target.value);
	};

	const values = {
		reports,
		_filterHandler,
	};

	return (
		<GlobalContext.Provider value={values}>
			<Spin spinning={loading}>
				<ReportsView />
			</Spin>
		</GlobalContext.Provider>
	);
};

export default DashboardTemplate(ReportsController);
