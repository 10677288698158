import React from "react";
import { Layout, Menu } from "antd";
import {
	CloseOutlined,
	UserOutlined,
	CheckOutlined,
	LogoutOutlined,
	HomeOutlined,
	QrcodeOutlined,
	BarChartOutlined,
	TeamOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Content, Footer, Sider } = Layout;

const DashboardTemplate = (Component) => {
	return class extends React.Component {
		state = {
			selectedKeys: ["0"],
		};

		UNSAFE_componentWillMount() {
			switch (this.props?.history?.location?.pathname) {
				case "/lgu-dashboard":
					this.setState({ selectedKeys: ["0"] });
					break;
				case "/lgu-for-approval":
					this.setState({ selectedKeys: ["1"] });
					break;
				case "/lgu-approved":
					this.setState({ selectedKeys: ["2"] });
					break;
				case "/lgu-denied":
					this.setState({ selectedKeys: ["3"] });
					break;
				case "/work-pass":
					this.setState({ selectedKeys: ["4"] });
					break;
				case "/lgu-dxform-all":
					this.setState({ selectedKeys: ["7"] });
					break;
				case "/reports":
					this.setState({ selectedKeys: ["5"] });
					break;
				default:
					break;
			}
		}

		render() {
			return (
				<Layout>
					<Sider
						breakpoint="lg"
						collapsedWidth="0"
						style={{ backgroundColor: "white" }}
					>
						<div style={{ margin: "10px 0" }}>
							<img
								src={require("../assets/app-logo.png")}
								style={{ width: "100%" }}
								alt="WeTrace App"
							/>
						</div>
						<Menu mode="inline" defaultSelectedKeys={this.state.selectedKeys}>
							<Menu.Item key="0" icon={<HomeOutlined />}>
								<Link to="/lgu-dashboard">Home</Link>
							</Menu.Item>
							<Menu.Item key="1" icon={<UserOutlined />}>
								<Link to="/lgu-for-approval">For Approval</Link>
							</Menu.Item>
							<Menu.Item key="2" icon={<CheckOutlined />}>
								<Link to="/lgu-approved">Approved</Link>
							</Menu.Item>
							<Menu.Item key="3" icon={<CloseOutlined />}>
								<Link to="/lgu-denied">Denied</Link>
							</Menu.Item>
							{localStorage.getItem("is_admin") === "0" && (
								<Menu.Item key="7" icon={<TeamOutlined />}>
									<Link to="/lgu-dxform-all">All</Link>
								</Menu.Item>
							)}
							<Menu.Item key="4" icon={<QrcodeOutlined />}>
								<Link to="/work-pass">Generate Work Pass</Link>
							</Menu.Item>
							{!localStorage.getItem("municipality_code") && (
								<Menu.Item key="5" icon={<BarChartOutlined />}>
									<Link to="/reports">Reports</Link>
								</Menu.Item>
							)}
							<Menu.Item key="6" icon={<LogoutOutlined />}>
								<Link to="/logout">Logout</Link>
							</Menu.Item>
						</Menu>
					</Sider>
					<Layout>
						<Content style={{ margin: "24px 16px 0" }}>
							<div
								style={{
									padding: 24,
									minHeight: 500,
									backgroundColor: "white",
								}}
							>
								<Component />
							</div>
						</Content>
						<Footer style={{ textAlign: "center" }}>
							&copy;2020 WeTrace App
						</Footer>
					</Layout>
				</Layout>
			);
		}
	};
};

export default DashboardTemplate;
