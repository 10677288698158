import React, { useContext } from "react";
import DataTable from "../../components/table";
import { GlobalContext } from "../../../helpers/global-context";
import { PageHeader, Modal, Button, Timeline, Row, Col } from "antd";
import IdentificationNew from "../../components/identification-pass";
import IdentificationSmall from "../../components/identification-small";
import GoogleMapReact from "google-map-react";
import googleMapsStyles from "./google-maps-styles";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import flag from "./helpers/flag.svg";
import marker from "./helpers/marker.svg";

const K_WIDTH = 40;
const K_HEIGHT = 40;

const ApprovedView = () => {
	const {
		dataSource,
		showID,
		info,
		center,
		currentPosition,
		activityLogs,
		print,
		setShowID,
		showGoogleMap,
		toggleHeapMap,
		setInfo,
		printRef,
		_printHandler,
		setShowGoogleMap,
	} = useContext(GlobalContext);

	const mapOptions = {
		styles: googleMapsStyles, // straight out of something like snazzymaps
	};

	return (
		<>
			<Modal
				visible={showID}
				closable={true}
				onCancel={() => {
					setShowID(false);
					setInfo(null);
				}}
				footer={null}
				width={440}
			>
				{info && <IdentificationSmall info={info} ref={printRef} />}
				{print && <IdentificationSmall info={print} ref={printRef} />}
				<div style={{ textAlign: "center", marginTop: 30 }}>
					<Button size="large" type="primary" onClick={_printHandler}>
						PRINT ID
					</Button>
				</div>
			</Modal>
			<Modal
				visible={showGoogleMap}
				closable={true}
				onCancel={() => setShowGoogleMap(false)}
				width={1000}
				footer={null}
			>
				<Row gutter={16}>
					<Col span={18}>
						<div style={{ width: "100%", height: 500 }}>
							<GoogleMapReact
								bootstrapURLKeys={{
									key: "AIzaSyA1FUMe2Cs-eJhRV8uJTNWXGtPT0jAB94A",
								}}
								center={center.center}
								zoom={center.zoom}
								heatmapLibrary={true}
								heatmap={toggleHeapMap}
								options={mapOptions}
							>
								<div
									style={{
										position: "absolute",
										width: K_WIDTH,
										height: K_HEIGHT,
										left: -K_WIDTH / 2,
										top: -K_HEIGHT,

										textAlign: "center",
										color: "#3f51b5",
										fontSize: 16,
										fontWeight: "bold",
										padding: 4,
									}}
									lat={currentPosition.lat}
									lng={currentPosition.lng}
								>
									{currentPosition.type == "C" ? (
										<Tippy
											theme="light"
											allowHTML={true}
											trigger="mouseenter click"
											hideOnClick={true}
											content={currentPosition.collisions}
										>
											<img
												style={{
													width: K_WIDTH,
													height: K_HEIGHT,
												}}
												src={currentPosition.type == "N" ? marker : flag}
											/>
										</Tippy>
									) : (
										<img
											style={{
												width: K_WIDTH,
												height: K_HEIGHT,
											}}
											src={currentPosition.type == "N" ? marker : flag}
										/>
									)}
								</div>
							</GoogleMapReact>
						</div>
					</Col>
					<Col span={6}>
						<div>
							<h3>Activity timeline</h3>
						</div>
						<div style={{ maxHeight: 500, overflow: "auto" }}>
							<Timeline mode={"left"}>{activityLogs}</Timeline>
						</div>
					</Col>
				</Row>
			</Modal>
			<PageHeader title="Approved Applications" />
			<DataTable dataSource={dataSource} />
		</>
	);
};

export default ApprovedView;
