import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, AppContext } from "../../helpers/global-context";
import LoginView from "./login-view";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { LOGIN } from "../../helpers/services";
import axios from "axios";

const LoginController = () => {
	const { setCookie, cookies } = useContext(AppContext);
	const [error, setError] = useState(false);
	const [spin, setSpin] = useState(false);
	const history = useHistory();

	useEffect(() => {
		cookies.token && history.push("/lgu-dashboard");

		const data = '{"username":"PH0316-314","password":"111111"}';
		const config = {
			method: "post",
			url: "https://uat.payruler.com/toyotatsusho-essapi/Login/login",
			headers: {
				"Payruler-Api-Key": "payruler_api_8915e07fc6c4b11",
				"Content-Type": "text/plain",
				Cookie: "payruler_system=vskicjvevt4vdhrlhien5ik9kh772dfk",
			},
			data: data,
		};

		axios(config)
			.then(function (response) {
				console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [cookies, history]);

	const _finishHandler = async (data) => {
		setSpin(true);
		const token = await LOGIN(data);
		if (token) {
			setCookie("token", token, {
				path: "/",
				expires: moment().add(8, "hours").toDate(),
			});
			if (
				!["dxform", "administrator"].includes(data.username) &&
				["D", "I"].includes(data.username.charAt(0))
			) {
				const municipality_code =
					data.username.match(/.{0,2}/g).join("-") + "0001";
				localStorage.setItem("municipality_code", municipality_code);
			} else if (data.username === "dxform") {
				localStorage.setItem("is_admin", "0");
			}
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} else {
			setError(true);
			setSpin(false);
		}
	};

	const values = {
		_finishHandler,
		error,
		spin,
	};

	return (
		<GlobalContext.Provider value={values}>
			<LoginView />
		</GlobalContext.Provider>
	);
};

export default LoginController;
